.messages{
  &.status{
    animation: flashbag 1s 4.5s ease-in-out forwards;
  }

  &.error{
    animation: flashbag 1s 9s ease-in-out forwards;
  }
  &.error, &.status{
  }
}
