@keyframes flashbag{
  from{
    opacity: 1;
    visibility: visible;
  }
  to{
    opacity: 0;
    visibility: hidden;
  }
}
