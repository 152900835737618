@import "anim";
@import "flashbag";

.webform-component--rgpd {
		flex: 1 1 0;
		display: flex;
		min-width: 200px;
}

.webform-component--rgpd:not(#_) {
		width: auto;
		margin-right: auto;
		margin-left: 0;
}

.webform-component--rgpd .form-checkboxes, .webform-component--rgpd .description {
		display: inline-block;
		vertical-align: top;
}

.webform-component--rgpd .description {
		max-width: calc(100% - 20px);
		font-size: 11px;
		line-height: normal;
}

.webform-component--rgpd .description a {
		color: #000;
		border-bottom: 2px solid #fff;
}

.webform-component--rgpd .description a:hover {
		color: #000;
		border-bottom-color: #000;
}

.webform-component--rgpd #edit-submitted-rgpd .form-type-checkbox {
		margin-top: 4px;
}

.webform-component--rgpd #edit-submitted-rgpd [type=checkbox] {
		position: absolute;
		z-index: -10;
}

.webform-component--rgpd #edit-submitted-rgpd [type=checkbox]:checked + label:after {
		content: '';
		position: absolute;
		top: 4px;
		right: 4px;
		bottom: 4px;
		left: 4px;
		background-color: #e0e0e0;
}

.webform-component--rgpd #edit-submitted-rgpd label {
		position: relative;
		z-index: 100;
		display: block;
		width: 20px;
		height: 20px;
		margin-right: 10px;
		font-size: 0.001px;
		cursor: pointer;
}

.webform-component--rgpd #edit-submitted-rgpd label:before {
		content: '';
		display: block;
		width: 16px;
		height: 16px;
		border: 2px solid #e0e0e0;
}

.webform-component--rgpd ~ .form-actions {
		flex: 0 1 auto;
		padding-left: 15px;
		margin-top: 0;
}

.webform-component--rgpd ~ .form-actions:not(#_) {
		width: auto;
}

.webform-component--rgpd ~ .form-actions .webform-submit {
		margin-top: 20px;
}

.webform-component--mention {
		white-space: nowrap;
		padding-right: 20px;
}

.webform-component--mention span {
		color: #000 !important;
		font-weight: bold;
}

.webform-component--mention:not(#_) {
		width: auto;
		flex: 0 1 auto;
}

.main-contact .form-required {
		color: #000;
}

.main-content form > div {
		justify-content: space-between;
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
}

/* CSS Document */
@font-face {
		font-family: "NexaRustSans-Book";
		src: url("font/NexaRustSans-Book.otf") format("opentype"), url("font/NexaRustSans-Book.woff") format("woff"), url("font/NexaRustSans-Book.ttf") format("truetype"), url("font/NexaRustSans-Book#NexaRustSans-Book") format("svg");
}

@font-face {
		font-family: "NexaRustSans-Black";
		src: url("font/NexaRustSans-Black.otf") format("opentype"), url("font/NexaRustSans-Black.woff") format("woff"), url("font/NexaRustSans-Black.ttf") format("truetype"), url("font/NexaRustSans-Black#NexaRustSans-Black") format("svg");
}

* {
		margin: 0;
		padding: 0;
		-webkit-text-size-adjust: none;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		outline: none;
}

a {
		text-decoration: none;
		color: #b19650;
}

a, a:hover, article .webform-client-form input[type=submit], article .webform-client-form input[type=submit]:hover {
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
}

#menu-header a:hover {
		text-decoration: underline;
}

ul {
		list-style: none;
}

img {
		border: 0;
		height: auto;
		max-width: 100%;
		vertical-align: bottom;
}

body {
		font: 400 15px/30px 'Work Sans', sans-serif;
		background-color: #000;
		color: #fff;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
}

.wrap {
		margin: 0 auto;
		width: 1400px;
}

.wrap::after {
		display: block;
		clear: both;
		content: " ";
}

.home-criteres .item.contentbloc, .home-criteres .item.contentbloc:hover, .home-criteres .item.title, .home-criteres:hover .item.title {
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-ms-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
}

.flex {
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		justify-content: space-between;
}

.field-collection-view {
		padding: 0;
		margin: 0;
}

@media all {
		/*************************************** HEADER ****************************************************/
		.iccookies_container a {
				color: #b19650;
		}
		.iccookies_container .close_iccookies {
				background-color: #b19650;
		}
		.lang-dropdown-select-element::-ms-expand {
				display: none;
		}
		body {
				overflow-x: hidden;
		}
		body.front h1, body.page-nos-produits h1 {
				position: absolute;
				text-indent: -999999px;
		}
		header {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
				padding: 0 40px;
				z-index: 50;
				height: 150px;
		}
		header.fix {
				background-color: rgba(0, 0, 0, 0.6);
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
		}
		header .header-right .pre-header {
				display: flex;
				justify-content: flex-end;
		}
		header .header-left #logo {
				float: left;
		}
		header .header-left {
				display: flex;
		}
		header .header-left #logo a img {
				margin: 15px 0;
		}
		header .header-left .menu {
				margin-top: 40px;
				margin-left: 20px;
				float: left;
		}
		header .header-right {
				display: flex;
				align-items: center;
		}
		header .flex {
				align-items: flex-start;
		}
		.cd-primary-nav ul.menu {
				font-size: 14px;
				text-transform: uppercase;
				font-family: 'NexaRustSans-Book';
		}
		.cd-primary-nav ul.menu a {
				color: white;
				transition: all 0.3s ease;
		}
		.cd-primary-nav ul.menu a:hover {
				color: #b19650;
		}
		.cd-primary-nav ul.menu li a::after {
				color: white;
				position: absolute;
				margin-top: -1rem;
				margin-left: 0.5rem;
				font-size: 3rem;
				content: ".";
		}
		.cd-primary-nav ul.menu li.last a::after {
				content: "";
		}
		header .langue {
				margin-left: 20px;
				position: relative;
				height: 55px;
		}
		header .langue svg {
				width: 55px;
		}
		header .langue svg polygon {
				fill: #b19650;
		}
		header .langue .lang_dropdown_form language .language-icon {
				display: none;
		}
		header .langue form {
				position: absolute;
				left: 16px;
				top: 15px;
		}
		header .langue form .language-icon {
				display: none;
		}
		header .langue form .form-item {
				background: transparent;
		}
		header .langue form select {
				background: transparent;
				border: none;
				text-transform: uppercase;
				font-family: 'NexaRustSans-Black';
				font-size: 15px;
				width: 50px !important;
				height: 40px;
				-webkit-appearance: none;
				cursor: pointer;
				-moz-appearance: none;
		}
		header .langue form select option {
				background: #000;
				border: none;
				text-transform: uppercase;
				font-family: 'NexaRustSans-Black';
				font-size: 14px;
				color: #fff;
				height: 30px;
				line-height: 30px;
		}
		header .langue form select option:hover {
				background: #000;
		}
		header .menu-secondaire {
				margin-top: 20px;
		}
		header .menu-secondaire ul.menu li {
				display: inline-block;
				color: #b19650;
				font-size: 13px;
				font-family: 'Work Sans';
				margin: 0;
		}
		header .menu-secondaire ul.menu li a {
				color: #b19650;
		}
		/*** MENU ***/
		nav li {
				display: inline;
		}
		nav a {
				color: #666666;
		}
		.page-actualites .view-actualites.homeactu .view-content .views-row {
				width: 100%;
				margin-bottom: 0;
		}
		header .flex .view-actualites.homeactu {
				background: #b19650;
				padding: 20px 30px;
				position: absolute;
				line-height: initial;
				max-width: 340px;
				top: 84px;
				margin-top: 0;
				transform: translateY(30px);
				opacity: 0;
				transition: all 0.4s ease;
				visibility: hidden;
		}
		header .flex .view-actualites.homeactu.show {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
		}
		header .flex .view-actualites.homeactu .item .date {
				font-size: 12px;
				color: #000;
				font-weight: bold;
				margin-bottom: 12px;
		}
		header .flex .view-actualites.homeactu .item .title {
				text-transform: uppercase;
				font-weight: 900;
				font-size: 16px;
				margin-bottom: 10px;
		}
		header .flex .view-actualites.homeactu .item p.rteright {
				text-align: right;
				margin-top: 4px;
		}
		header .flex .view-actualites.homeactu .item p.rteright a {
				color: #fff;
				font-size: 12px;
		}
		header .flex .view-actualites.homeactu::before {
				content: '';
				background: url(../images/border-top-actualites.png) no-repeat center center;
				position: absolute;
				left: 38%;
				top: -11px;
				width: 21px;
				height: 11px;
		}
		.overlay-menu {
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #b19650;
				transform: translateY(-100%);
				display: flex;
				justify-content: center;
				transition: all 0.5s ease;
				z-index: 50;
				flex-direction: column;
				padding: 35px;
				box-sizing: border-box;
		}
		.overlay-menu.active {
				transform: translateX(0);
		}
		.overlay-menu .menu li a {
				color: #fff;
				font-family: 'NexaRustSans-Black';
				text-transform: uppercase;
				line-height: 3rem;
				font-size: 20px;
				margin: 10px 0;
		}
		.overlay-menu .menu li .nolink {
				display: none;
		}
		.overlay-menu .menu li {
				list-style-type: none;
				list-style-image: none;
		}
		.overlay-menu .view-actualites {
				display: none;
		}
		.trigger-menu {
				width: 60px;
				height: 60px;
				position: absolute;
				cursor: pointer;
				right: 5px;
				top: 20px;
				z-index: 60;
				display: none;
		}
		.trigger-menu span {
				display: block;
				width: 25px;
				height: 2px;
				background-color: #fff;
				position: absolute;
				transition: all 0.3s ease;
		}
		.trigger-menu span:nth-child(1) {
				left: 18px;
				top: 20px;
		}
		.trigger-menu span:nth-child(2) {
				left: 18px;
				top: 28px;
		}
		.trigger-menu span:nth-child(3) {
				left: 18px;
				top: 36px;
		}
		.trigger-menu span:nth-child(4) {
				left: 18px;
				top: 28px;
		}
		.trigger-menu.cross span:nth-child(1), .trigger-menu.cross span:nth-child(3) {
				opacity: 0;
				visibility: hidden;
		}
		.trigger-menu.cross span:nth-child(2), .trigger-menu.cross span:nth-child(4) {
				transform-origin: center center;
		}
		.trigger-menu.cross span:nth-child(2) {
				transform: rotateZ(45deg);
		}
		.trigger-menu.cross span:nth-child(4) {
				transform: rotateZ(-45deg);
		}
		/*************************************** ACCUEIL ****************************************************/
		.diaporama {
				width: 100%;
				height: 650px;
				position: relative;
				overflow: hidden;
		}
		.diaporama .slide-accueil {
				height: 100%;
		}
		.diaporama .slide-accueil .slides {
				height: 100%;
		}
		.diaporama .slide-accueil .field-collection-container {
				height: 100%;
		}
		.diaporama .slide-accueil .field-collection-container .owl-stage-outer {
				height: 100% !important;
		}
		.diaporama .slide-accueil .field-collection-container .owl-stage-outer .owl-stage {
				height: 100%;
		}
		.diaporama .slide-accueil .field-collection-container .owl-stage-outer .owl-stage .owl-item {
				float: left;
				height: 100%;
		}
		.diaporama .slide-accueil .field-collection-view > img {
				width: 100%;
				height: auto;
				min-height: 100%;
				position: absolute;
				object-fit: cover;
				top: 0;
				left: 0;
		}
		.diaporama {
				.slide-accueil {
						.field-collection-view {
								position: relative;
								height: 100%;
								width: 100%;
						}

						.caption {
								position: relative;
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								height: 100%;
								margin-top: auto;
								margin-bottom: auto;
								text-align: center;

								img {
										width: 250px;
										height: auto;
										position: relative;
										min-width: auto;
										min-height: auto;
										z-index: 1;
								}
						}

						.more a, .custom-link a {
								background: rgba(255, 255, 255, 0.8);
								font-family: 'NexaRustSans-Black';
								text-transform: uppercase;
								font-size: 15px;
								padding: 25px;
								box-sizing: border-box;
								color: #000;
								display: block;
								position: relative;
								top: -12px;
								transition: all 0.35s ease;

								&::before {
										content: ">";
										margin-right: 6px;
								}

								&:hover {
										background: #fff;
								}
						}

						.custom-link {
								position: absolute;
								right: 50%;
								bottom: 0;
								width: 100%;
								transform: translate(50%, -55px);
								text-align: center;

								a {
										display: inline-block;
								}
						}

						@media all and (max-width: 535px) {
								.caption {
										padding-top: 30px;
								}
								.custom-link, .more {
										a {
												line-height: 1.2;
										}
								}
								.custom-link {
										position: static;
										transform: none;

										a{
												position: static;
												padding-top: 15px;
												padding-bottom: 15px;
												line-height: 1;
												transform: translateY(-100%);
										}
								}
						}
						@media all and (max-width: 400px) {
								.custom-link {
										right: 0;
										width: 100%;
								}
								.caption {
										img {
												height: 130px;
												width: auto;
										}
								}
						}
				}
		}

		.diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				margin: auto;
				right: 0;
		}
		.diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption .chapo {
				background-color: rgba(255, 255, 255, 0.8);
				width: 770px;
				padding: 30px;
				position: absolute;
				top: 120px;
				left: 0;
				right: 0;
				margin: auto;
		}
		.diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption h1 {
				font: 300 80px 'NexaRustSans-Book', sans-serif;
				color: #fff;
				display: inline-block;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 50px;
		}
		.diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption h2 {
				margin-bottom: 10px;
				color: #000;
		}
		.owl-prev, .owl-next {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-o-transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				font-size: 0;
		}
		.owl-prev:after, .owl-next:after {
				content: "";
				display: block;
				width: 50px;
				height: 50px;
				background: rgba(255, 255, 255, 0.6);
				position: absolute;
				left: 0;
				top: 0;
				border-radius: 50%;
				cursor: pointer;
				transition: all 0.4s ease;
		}
		.owl-prev:hover:after, .owl-next:hover:after {
				background: #fff;
		}
		.owl-next:after {
				right: 0;
				left: auto;
		}
		.owl-prev:before, .owl-next:before {
				content: "";
				display: block;
				width: 12px;
				height: 18px;
				background: url(../images/arrow.png) no-repeat center center;
				background-size: cover;
				position: absolute;
				left: 17px;
				top: 16px;
				z-index: 1;
				cursor: pointer;
		}
		.owl-next:before {
				left: auto;
				right: 17px;
				transform: rotateZ(180deg);
		}
		.owl-prev {
				left: 30px;
		}
		.owl-next {
				right: 30px;
		}
		.search .wrap .frmSearch #search-box {
				background: url(../images/loupe-search.png) no-repeat left 10px center black;
				border: 1px solid rgba(255, 255, 255, 0.8);
				height: 38px;
				color: white;
				width: 370px;
				padding: 0 7px 0 38px;
				float: right;
				font: 400 14px/25px 'Work Sans', sans-serif;
		}
		.search .wrap .frmSearch #search-box input:-webkit-autofill {
				background: url(../images/loupe-search.png) no-repeat left 10px center black !important;
				color: white !important;
		}
		.home-criteres {
				padding: 15px 0;
				border-bottom: 2px solid #000;
				width: 100%;
				background-color: #000;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
				align-items: center;
				transition: all 0.3s;
				background-color: #7c6938;
				justify-content: center;
		}
		.home-criteres:hover {
				background-color: #b19650;
				transition: all 0.3s;
		}
		.home-criteres:hover .item.title {
				background-position: right 13% center;
		}
		.home-criteres:hover .item.contentbloc + .item.contentbloc {
				border-left: 2px solid #b19650;
				transition: all 0.3s;
		}
		.home-criteres .item.title span.critere-name {
				font: 400 55px 'Dancing Script', cursive;
		}
		.home-criteres .item {
				flex-grow: 1;
				width: 25%;
				text-align: center;
				position: relative;
		}
		.home-criteres .item.contentbloc {
				background-position: center;
				height: 310px;
				background-repeat: no-repeat;
				background-size: auto;
				background-color: #161616;
		}
		.home-criteres .item.title {
				color: #000;
				line-height: initial;
				font-size: 18px;
				padding: 20px 5%;
				text-align: left;
				background: url(../images/titre-bloc-accueil.png) no-repeat right 17% center;
		}
		.home-criteres .item .imagecover {
				background-color: rgba(0, 0, 0, 0.6);
				width: 100%;
				height: 100%;
				transition: all 0.3s;
		}
		.home-criteres .item .imagecover:hover, .home-criteres .item:hover .imagecover {
				background-color: rgba(0, 0, 0, 0);
				transition: all 0.3s;
		}
		.home-criteres .item span.title {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: opacity 0.25s;
				font: 700 26px 'Quattrocento', serif;
				text-transform: uppercase;
				color: #fff;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				padding: 8px 2px;
				letter-spacing: 0.1em;
		}
		.home-marques a .imagecover {
				display: none;
		}
		.home-criteres .item.home-marques span.title {
				display: none;
		}
		.home-marques a .title_hover {
				display: none;
		}
		.home-marques a {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
		}
		.home-marques {
				position: relative;
				opacity: 0.9;
		}
		.home-marques:hover {
				opacity: 1;
		}
		/*************************************** CONTENU ****************************************************/
		article .en-tete + div {
				margin-top: 70px;
		}
		/***** fil d'ariane ****/
		.search {
				width: 100%;
				padding: 20px 0;
				background-color: #000;
				z-index: 2;
				position: relative;
		}
		.search #breadcrumb .breadcrumb {
				padding-bottom: 0;
		}
		.search #breadcrumb span {
				color: #787878;
				text-transform: uppercase;
				font-family: 'NexaRustSans-Black';
		}
		.search #breadcrumb a span {
				font-family: 'NexaRustSans-Book';
		}
		.messages {
				padding: 0 !important;
				margin: 0 !important;
				border: none !important;
		}
		.messages.status,
		.messages.error {
				text-align: center;
				background-color: #b19650;
				font-size: 17px;
				padding: 25px !important;
				background-image: none;
				color: #fff;
				border: 1px solid #fff !important;
				left: 0;
				margin-left: auto !important;
				margin-right: auto !important;
				position: fixed;
				right: 0;
				top: 15%;
				width: 50%;
				z-index: 9999;
		}
		/******************* CONTENU **************************/
		article {
				background: url(../images/pattern01.jpg) repeat center center;
				color: #000000;
				padding-bottom: 100px;
		}
		body.node-type-page-entreprise article {
				padding-bottom: 0;
		}
		article .en-tete {
				min-height: 500px;
				position: relative;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
		}
		article h1, body.page-nos-actualites .en-tete h1, body.page-nos-marques .diaporama .title {
				font: 300 50px 'NexaRustSans-Book', sans-serif;
				color: #fff;
				display: inline-block;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				left: 50%;
				text-transform: uppercase;
				text-align: center;
				max-width: 80%;
		}
		.h1pagebase {
				font: 300 57px 'NexaRustSans-Book', sans-serif;
				color: #000;
				display: inline-block;
				text-transform: uppercase;
				margin: 7rem 0 2rem 0;
				position: static;
				text-align: center;
				width: 100%;
		}
		.page-actualites .view-actualites .view-header {
				text-align: center;
		}
		.page-actualites .view-actualites .view-header h1 {
				font: 300 60px 'NexaRustSans-Book', sans-serif;
				color: #000;
				display: inline-block;
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 50px;
		}
		article h2 {
				font-size: 25px;
				font-weight: normal;
				color: #b19650;
				margin-bottom: 20px;
		}
		article p {
				line-height: 26px;
				text-align: justify;
		}
		article li {
				line-height: 26px;
				padding-left: 1em;
				text-indent: -1em;
				text-align: justify;
		}
		article li li {
				margin-left: 20px;
		}
		article li ul {
				margin-bottom: 5px;
		}
		article li::before {
				content: "\2022\ ";
				color: #b19650;
				padding-right: 5px;
		}
		article .main-content {
				padding-top: 110px;
		}
		article .main-content .wrap {
				padding: 0 150px;
		}
		article.entreprise {
				background-color: #000000;
				color: #ffffff;
		}
		article .en-tete .menu-ancres {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 15%;
		}
		article .en-tete .menu-ancres ul {
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
				justify-content: center;
		}
		article .en-tete .menu-ancres ul li {
				display: inline-block;
				padding-left: 0;
				text-indent: 0;
		}
		article .en-tete .menu-ancres ul li::before {
				display: none;
		}
		article .en-tete .menu-ancres ul li + li {
				margin-left: 20px;
		}
		article .en-tete .menu-ancres ul li a {
				display: block;
				padding: 30px 70px;
				background: rgba(255, 255, 255, 0.8);
				text-transform: uppercase;
				font-family: 'NexaRustSans-Black';
				color: #000000;
				transition: all 0.35s ease;
		}
		article .en-tete .menu-ancres ul li a:hover {
				background: white;
				color: #b19650;
		}
		body.node-type-page-entreprise .searchproduct, body.node-type-webform .searchproduct {
				display: none;
		}
		/* Sapresti Mag */
		article.mag {
				padding-bottom: 0;
		}
		article.mag .saprestimag {
				background-color: #b19650;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
		}
		article.mag .saprestimag > div {
				flex: 3 0 0%;
		}
		article.mag .saprestimag .menusapresti {
				flex: 1 0 0%;
				padding: 50px;
				margin-top: 50px;
		}
		article.mag .saprestimag .menusapresti ul.menu li.leaf, article.mag .saprestimag .menusapresti ul.menu li.active-trail {
				list-style-type: none;
				list-style-image: none;
				text-indent: 0;
		}
		article.mag .saprestimag .menusapresti ul.menu li.leaf::before, article.mag .saprestimag .menusapresti ul.menu li.active-trail::before {
				display: none;
		}
		article.mag .saprestimag .menusapresti ul.menu li span.nolink {
				text-transform: uppercase;
				font-family: 'NexaRustSans-Black';
				font-size: 14px;
				color: #000;
		}
		article.mag .saprestimag .menusapresti ul.menu ul.menu li a {
				text-transform: uppercase;
				font-family: 'NexaRustSans-Book';
				font-size: 14px;
				color: #000;
		}
		article.mag .saprestimag .menusapresti ul.menu ul.menu li a:hover {
				color: #fff;
		}
		article.mag .saprestimag .menusapresti ul.menu ul.menu li.active-trail a {
				color: #fff;
				font-family: 'NexaRustSans-Black';
		}
		article.mag .contentmag .content {
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
		}
		article.mag .contentmag .content .left {
				flex: 2 0 0%;
		}
		article.mag .contentmag .content .right {
				flex: 1 0 0%;
		}
		article.mag .contentmag .content .bottomcontent {
				flex: 1 0 auto;
				width: 100%;
		}
		article.mag .contentmag .content .bottomleft {
				display: flex;
				flex-wrap: wrap;
		}
		article.mag .contentmag .content .bottomleft .blocmag {
				width: 50%;
		}
		article.mag .contentmag .blocmag {
				position: relative;
				border: 1px solid #000;
		}
		article.mag .contentmag .blocmag > img {
				width: 100%;
		}
		article.mag .contentmag .blocmag .caption h2 {
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 0.1em;
				position: relative;
				margin-bottom: 20px;
				padding: 15px 0;
				text-align: left;
				font: 300 26px 'Quattrocento', serif;
				letter-spacing: 3px;
		}
		article.mag .contentmag .blocmag .caption {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 100;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.8);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				padding: 0 35px;
				opacity: 0;
				transition: all 0.4s ease;
		}
		article.mag .contentmag .blocmag:hover {
				transform: none;
				transition: none;

				.caption {
						opacity: 1;
						transform: none;
				}
		}
		article.mag .contentmag .blocmag .caption h2::before, article.mag .contentmag .blocmag .caption h2::after {
				content: "";
				width: 35px;
				background: #fff;
				position: absolute;
				bottom: 0;
				height: 1px;
				left: 0px;
		}
		article.mag .contentmag .blocmag .caption h2::before {
				bottom: initial;
				top: 0;
		}
		article.mag .contentmag .blocmag .caption p {
				line-height: 17px;
				font-size: 13px;
				color: #fff;
		}
		article.mag .contentmag .blocmag .caption > * {
				width: 75%;
		}
		/*article.mag .contentmag .bottomcontent .blocmag{display: flex; box-sizing: border-box;/* padding: 40px 60px;*/
		/*}
			article.mag .contentmag .bottomcontent .blocmag .image{margin-right: 50px;}
			article.mag .contentmag .bottomcontent .blocmag .image img{box-shadow: -4px 4px 6px 0px rgba(0,0,0,0.4);}

			article.mag .contentmag .bottomcontent .blocmag .texte{display: flex; flex-direction: column; justify-content: space-between;}
			article.mag .contentmag .bottomcontent .blocmag .texte h2{position: relative; margin-top: 12px; font: 300 32px 'Quattrocento', serif; color: #b19650; text-transform: uppercase; letter-spacing: 3px;}
			article.mag .contentmag .bottomcontent .blocmag .texte h2:before,article.mag .contentmag .bottomcontent .blocmag .texte h2:after{content: "";width : 50px;  background: #b19650; position: absolute; bottom: 0; height: 1px; left: 0}
			article.mag .contentmag .bottomcontent .blocmag .texte h2:before{top: -12px;}
			article.mag .contentmag .bottomcontent .blocmag .texte h2:after{bottom: -12px;}
			article.mag .contentmag .bottomcontent .blocmag .texte h3{font-family : 'NexaRustSans-Black'; font-size: 14px; text-transform: uppercase; line-height: 18px;}

	*/
		/*
		 Produit */
		.aimerez-aussi {
				background-color: #b19650;
				border-bottom: 2px solid #000;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
		}
		.aimerez-aussi h2 {
				font: 400 32px 'Dancing Script', cursive;
				color: #000;
				line-height: initial;
				padding: 20px 2%;
				text-align: left;
				background: url(../images/titre-bloc-accueil.png) no-repeat right 17% center;
				width: 20%;
		}
		.aimerez.owl-carousel {
				width: 80%;
				max-width: 80%;
		}
		.item-aimerez-aussi {
				width: 100%;
				text-align: center;
				position: relative;
				border-left: 1px solid #000;
				border-right: 1px solid #000;
				padding: 5px;
				box-sizing: border-box;
		}
		.item-aimerez-aussi img {
				width: 100%;
		}
		.item-aimerez-aussi p {
				position: absolute;
				left: 0;
				bottom: 0;
				text-align: center;
				font-family: 'NexaRustSans-Black';
				text-transform: uppercase;
				text-align: center;
				margin-bottom: 40px;
				width: 100%;
				padding: 0 20px;
				line-height: 23px;
		}
		.item-aimerez-aussi p.readmore {
				padding: 0;
		}
		.item-aimerez-aussi p.readmore a {
				bottom: -65px;
				background-color: #b19650;
				color: black;
				height: 40px;
				text-transform: uppercase;
				text-align: center;
				transform: translateY(60px);
				transition: all 0.4s ease;
				font-size: 15px;
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				padding: 10px;
		}
		.node-type-produit .bandeau .owl-carousel img + p {
				display: none;
		}
		.item-aimerez-aussi:hover p.readmore a {
				bottom: 25px;
		}
		.item-aimerez-aussi p a {
				color: #fff;
				display: inline-block;
		}
		.aimerez-aussi .aimerez.owl-carousel {
				flex: 1 0 0%;
		}
		.node-type-produit .bandeau .owl-carousel {
				height: 650px;
				position: relative;
				overflow: hidden;
		}
		.node-type-produit .bandeau .owl-carousel img {
				object-fit: cover;
				margin: 0 auto;
				/* max-width: none; width: auto;*/
		}
		.node-type-produit .fiche-produit .owl-carousel img {
				width: auto;
				margin: 0px auto -50px;
		}
		.fiche-produit .details {
				display: flex;
				flex-wrap: wrap;
		}
		.fiche-produit .details-slides, .fiche-produit .details-info {
				width: 50%;
		}
		.fiche-produit .details-info {
				display: flex;
				justify-content: center;
				box-sizing: border-box;
				padding: 50px 40px;
				background: url(../images/pattern01.jpg) repeat;
				flex-direction: column;
				align-items: center;
		}
		.fiche-produit .details-info h1 {
				font: 300 32px 'Quattrocento', serif;
				text-transform: uppercase;
				letter-spacing: 3px;
				color: #787878;
				position: relative;
				margin-bottom: 5px;
		}
		.fiche-produit .details-info .title {
				text-align: center;
				position: relative;
				margin-bottom: 50px;
				width: 500px;
				padding: 20px 0;
		}
		.fiche-produit .details-info h2 {
				font: 300 26px 'Quattrocento', serif;
				color: #000;
				margin-bottom: 5px;
		}
		.fiche-produit .details-info .title:before, .fiche-produit .details-info .title:after {
				content: "";
				display: block;
				width: 60px;
				height: 1px;
				background-color: #787878;
				position: absolute;
				top: 0;
				left: 0;
		}
		.fiche-produit .details-info .title:after {
				top: auto;
				bottom: 0;
		}
		.fiche-produit .details-info .accroche {
				font-family: 'NexaRustSans-Black';
				color: #b19650;
				text-transform: uppercase;
				max-width: 520px;
				text-align: center;
				font-weight: 700;
				line-height: 20px;
		}
		.fiche-produit .details-info .description {
				color: #000;
				max-width: 520px;
				text-align: center;
				margin-top: 40px;
				margin-bottom: 60px;
		}
		.fiche-produit .details-info .infos-comp {
				font-size: 12px;
				color: #000;
				font-weight: 500;
				line-height: 18px;
				margin-top: 20px;
				width: 100%;
		}
		.fiche-produit .details-info .existe-aussi {
				text-align: center;
		}
		.fiche-produit .details-info .existe-aussi img {
				width: 100px;
				height: auto;
				margin: 0 auto;
		}
		.fiche-produit .details-info .existe-aussi h2 {
				font: 400 35px 'Dancing Script', cursive;
		}
		.node-type-produit .bandeau {
				position: relative;
		}
		.node-type-produit .bandeau .marqueproduit {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				text-align: center;
				z-index: 40;
		}
		.node-type-produit .bandeau .descriptionmarque {
				background-color: rgba(255, 255, 255, 0.8);
				padding: 30px;
				max-width: 900px;
				color: #000;
				margin: auto;
		}
		.node-type-produit .bandeau .descriptionmarque .marquename {
				text-transform: uppercase;
				font-weight: bold;
				font-size: 20px;
		}
		/***************** Contact (formulaire) *******************************/
		body.node-type-webform .main-content .wrap {
				padding: 0 20%;
		}
		article #userForm {
				clear: both;
				float: left;
		}
		article form {
				width: 100%;
				margin: auto;
				display: inline-block;
				margin-bottom: 60px;
		}
		article form .form-item {
				width: 49%;
				margin: 0 0 20px 0;
				float: left;
		}
		/* article form .form-item:nth-child(even) {margin-left : 2% ;} */
		article form .form-item.webform-component-textarea {
				width: 100%;
				margin-left: 0;
		}
		article .webform-client-form input[type=text], article .webform-client-form input[type=email],
		article .webform-client-form input[type=file], article .webform-client-form .rsform-select-box, article .webform-client-form textarea {
				background-color: #fff;
				height: 38px;
				border-radius: 0;
				width: 100%;
				font: 400 15px/30px 'Work Sans', sans-serif;
				padding: 5px 10px;
				border: 1px solid #c4c4c4;
		}
		article .webform-client-form textarea {
				height: 200px;
		}
		article .webform-client-form input[type=file], article .webform-client-form .rsform-select-box {
				height: auto;
				padding: 0;
		}
		article .webform-client-form label {
				margin: 0 15px 0 5px;
		}
		article .webform-client-form input[type="radio"] {
				margin: 10px 0;
		}
		article .webform-client-form .formHorizontal .rsform-block {
				margin-bottom: 0px;
		}
		article .webform-client-form .form-required {
				color: #b19650;
		}
		article .cnil {
				font-style: italic;
				font-size: 15px;
				margin-top: 30px;
		}
		article p strong {
				font-weight: normal;
				color: #b19650;
		}
		article .webform-client-form input[type=submit] {
				width: 170px;
				font-size: 25px;
				font-weight: normal;
				background-color: #b19650;
				margin-bottom: 20px;
				float: right;
				color: #fff;
				height: 45px;
				margin: auto;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				border: none;
				font: 400 20px/30px 'Work Sans', sans-serif;
				cursor: pointer;
				text-transform: uppercase;
		}
		article .webform-client-form input[type=submit]:hover {
				background: #000;
		}
		/********************************* Blog ********************************
			.blog .items-row{padding: 50px 0; border-bottom: 1px solid #B0A696;}
			.img-intro-left{float:left; margin-right:30px; width:35%;}
			.img-intro-left img { width:100%;}
			.blog .items-row p.readmore { margin-top:20px; text-align:right;}
			*/
		/* Pagination
					.pagination{ clear:both; display:block; margin:auto; width:100%; margin-top: 20px;}
					.pagination ul {display:table; margin:auto; border-collapse: separate; }
					.pagination li {display:table-cell; padding-right: 15px; padding-left:inherit; text-indent:inherit;}
					article .pagination li::before{content:none; }
					.pagination li span, .pagination li span.current, .pagination li a{ width:50px; height:50px; display:block; border-radius:50%;  font-weight: 700; font-size: 20px; line-height:50px; text-align: center; border:none; box-shadow: none; background-image:none;}
					.pagination li a{ background:#f9f9f9; text-align:center; color:#000;-webkit-transition: all 500ms ease-in-out; -o-transition: all 500ms ease-in-out; transition: all 500ms ease-in-out;}
					.pagination li a:hover{background:#EB1B42; color:#fff;}
					.pagination li span, .pagination li span.current{background: #EB1B42; color:#fff;}
					.pagination li:first-child, .pagination li:last-child{display:none; border:none;}
					.pagination .pagination-prev, .pagination .pagination-next { display:none;}
			*/
		/******************* ASIDE **************************/
		aside {
				float: right;
		}
		/*************************************** PIED DE PAGE ****************************************************/
		footer {
				background: #000;
				padding: 55px 0 65px;
				font-size: 13px;
				color: #fff;
		}
		footer .wrap {
				padding: 0 45px;
		}
		footer .wrap .flex > div {
				flex: 1 0 0%;
		}
		footer #pre-footer ul li {
				width: 30%;
				float: left;
				margin: 0 2% 15px 0;
				list-style-type: none;
				list-style-image: none;
				line-height: initial;
		}
		footer #pre-footer ul li:nth-child(3n+1) {
				margin-left: 0;
		}
		footer #pre-footer ul li a, footer #pre-footer ul li span {
				color: #fff;
				text-transform: uppercase;
				font-size: 14px;
		}
		footer .right {
				text-align: right;
		}
		footer .right .menu-footer {
				margin-bottom: 20px;
		}
		footer .right .menu-footer ul {
				text-align: right;
		}
		footer .right .menu-footer ul li {
				display: inline-block;
		}
		footer .right .menu-footer ul li a {
				color: #fff;
		}
		footer .right .menu-footer ul li + li {
				margin-left: 15px;
		}
		footer .right .menu-footer ul li a:hover, footer #pre-footer ul li a:hover {
				color: #929392;
		}
		footer .right .menu-footer ul li + li::before {
				content: '|';
				padding-right: 15px;
		}
		footer .right .tri, footer .right .social {
				color: #666666;
				display: inline-block;
				float: right;
				line-height: 23px;
		}
		footer .right .tri a {
				display: block;
				color: #666666;
		}
		footer .right .tri table, footer .right .tri tbody, footer .right .tri tbody td {
				border: none;
		}
		footer .right .tri tbody td:first-child {
				padding-right: 15px;
		}
		footer .right .social {
				margin-left: 30px;
		}
		footer .right .tri {
				max-width: 470px;
		}
}
